import axios from 'axios'
import cookie from 'react-cookies'



export const PostData = (mainUrl, url, data) => {
  let headers = {
    'Content-Type': 'application/json',
    'X-localization': 'en'
  }
  return axios
    .post(mainUrl + url, data, { headers: headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      let errorStatus = JSON.parse(JSON.stringify(error.response))
      return errorStatus
    })
}

export const GetData = (mainUrl, url, data) => {
  let headers = {
    'Content-Type': 'application/json',
    'ngrok-skip-browser-warning': '123'
  }
  return axios
    .get(mainUrl + url, data, { headers: headers })
    .then(resposne => {
      return resposne.data
    })
    .catch(error => {
      let errorStatus = JSON.parse(JSON.stringify(error.response))
      return errorStatus
    })
}

export const PostDataWithToken = (mainUrl, url, data) => {
  console.log('data :-', data)
  let tokens = ''
  if (cookie.load('token')) {
    tokens = cookie.load('token')
  }
  let headers = {
    Authorization: 'Bearer ' + tokens,
    'Content-Type': 'application/json'
  }
  return axios
    .post(mainUrl + url, data, { headers: headers })
    .then(response => {
      console.log('///====edit user response====/////', response)
      return response.data
    })
    .catch(error => {
      // let errorStatus = JSON.parse(JSON.stringify(error.response))
      // return errorStatus
    })
}

export const PostImageDataWithToken = (mainUrl, url, data) => {
  let tokens = ''
  if (cookie.load('token')) {
    tokens = cookie.load('token')
  }
  let headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: 'Bearer ' + tokens
  }
  return axios
    .post(mainUrl + url, data, { headers: headers })
    .then(response => {
      return response.data
    })
    .catch(error => {
      let errorStatus = JSON.parse(JSON.stringify(error.response))
      return errorStatus
    })
}

export const GetDataWithToken = (mainUrl, url) => {
  let tokens = ''
  if (cookie.load('token')) {
    tokens = cookie.load('token')
  }
  let config = {
    headers: {
      Authorization: 'Bearer ' + tokens,
      'ngrok-skip-browser-warning': '123'
    },
    params: {}
  }
  return axios
    .get(mainUrl + url, config)
    .then(resposne => {
      return resposne.data
    })
    .catch(error => {
      let errorStatus = JSON.parse(JSON.stringify(error.response))
      return errorStatus
    })
}
