import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaCarAlt, FaFileAlt } from 'react-icons/fa';
import { MdErrorOutline } from 'react-icons/md';
import { useSelector } from 'react-redux';



const Sidebar = ({ toggleSideNav }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeMain, setActiveMain] = useState(null);
  const [activeSub, setActiveSub] = useState(null);
  const userDetails = useSelector(state => state?.root?.userDetails)

  const handleMainClick = (mainOption, mainIndex) => {
    if (!mainOption.sub || mainOption.sub.length === 0) {
      setActiveMain(mainIndex); // Set active main menu item
      setActiveSub(null); // Clear sub-menu selection
      navigate(mainOption.path);
    } else {
      setActiveMain((prevIndex) => (prevIndex === mainIndex ? null : mainIndex));
    }
  };
  
const sideBarArr = [
  {
    path: '',
    icon: <FaFileAlt />,
    name: 'Mapping',
    sub: [
      {
        path: '/mmv',
        icon: <FaCarAlt />,
        name: 'MMV',
      },
      // Conditionally include 'RTO' only if productOwner is not 'EC'
      ...(userDetails?.productOwner !== 'EC'
        ? [
            {
              path: '/rto',
              icon: <FaCarAlt />,
              name: 'RTO',
            },
          ]
        : []),
    ],
  },
  // Conditionally add these items only if productOwner is not 'EC'
  ...(userDetails?.productOwner !== 'EC'
    ? [
        {
          path: '/quoteError',
          icon: <MdErrorOutline />,
          name: 'Quote Error',
          sub: [], // No sub-options
        },
        {
          path: '/tpError',
          icon: <MdErrorOutline />,
          name: 'Tp Error',
          sub: [], // No sub-options
        },
        // {
        //   path: '/addMmv',
        //   icon: <MdErrorOutline />,
        //   name: 'Add Mastars',
        //   sub: [
        //     {
        //       path: '/addMmv',
        //       icon: <FaCarAlt />,
        //       name: 'Add MMV',
        //     },
        //   ], 
        // },
      ]
    : []),
];

  const handleSubClick = (subOption) => {
    navigate(subOption.path);
    setActiveSub(subOption.path); // Set active sub-menu item
  };

  const isSubOptionActive = (subPath) => location.pathname === subPath;

  useEffect(() => {
    sideBarArr.forEach((mainOption, mainIndex) => {
      if (Array.isArray(mainOption.sub)) {
        mainOption.sub.forEach((subOption) => {
          if (location.pathname === subOption.path) {
            setActiveMain(mainIndex);
            setActiveSub(subOption.path);
          }
        });
      } else if (location.pathname === mainOption.path) {
        setActiveMain(mainIndex);
        setActiveSub(null);
      }
    });
  }, [location.pathname]);

  return (
    <aside
      className={`sidenav navbar navbar-vertical navbar-expand-xs border-radius-xl my-3 fixed-start m-1 ${
        toggleSideNav ? 'sideOpen' : ''
      }`}
      id="sidenav-main"
    >
      <div className="sidenav-header">
        <img src="/assets/img/logo1.png" width="100px" alt="Logo" />
      </div>
      <hr className="horizontal dark mt-0" />
      <div
        className="collapse navbar-collapse w-auto max-height-vh-100 h-100"
        id="sidenav-collapse-main"
      >
        <ul className="navbar-nav">
          {sideBarArr.map((mainOption, mainIndex) => (
            <div
              key={mainIndex}
              style={{ marginTop: '10px' }}
              className="nav-item"
            >
              <div
                onClick={() => handleMainClick(mainOption, mainIndex)}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '10px',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  boxShadow: '0 20px 27px 0 rgba(0, 0, 0, 0.05)',
                  // backgroundColor:
                  //   activeMain === mainIndex ? '#007BFF' : '#fff',
                  // color: activeMain === mainIndex ? '#FFF' : '#344767',
                }}
                className={activeMain === mainIndex ? 'active' : 'nav-item'}
              >
                <div
                  className="icon icon-sm shadow border-radius-md text-center me-2 d-flex align-items-center justify-content-center"
                  style={{
                    backgroundColor:
                      activeMain === mainIndex ? '#007BFF' : '#fff',
                    color: activeMain === mainIndex ? '#FFF' : '#000',
                  }}
                >
                  {mainOption.icon}
                </div>
                <span style={{ marginLeft: '10px' }}>{mainOption.name}</span>
              </div>

              {activeMain === mainIndex && mainOption.sub && (
                <div style={{ marginLeft: '20px' }}>
                  {mainOption.sub.map((subOption, subIndex) => (
                    <div
                      key={subIndex}
                      onClick={() => handleSubClick(subOption)}
                      style={{
                        marginTop: '5px',
                        padding: '8px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                      }}
                    >
                      <Link
                        to={subOption.path}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          textDecoration: 'none',
                          color: isSubOptionActive(subOption.path)
                            ? '#007BFF'
                            : '#000',
                        }}
                      >
                        <div
                          className="icon icon-sm shadow border-radius-md text-center me-2 d-flex align-items-center justify-content-center"
                          style={{
                            backgroundColor: isSubOptionActive(subOption.path)
                              ? '#007BFF'
                              : '#fff',
                            color: isSubOptionActive(subOption.path)
                              ? '#FFF'
                              : '#000',
                          }}
                        >
                          {subOption.icon}
                        </div>
                        <span style={{ marginLeft: '10px' }}>
                          {subOption.name}
                        </span>
                      </Link>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </ul>
      </div>
    </aside>
  );
};

export default Sidebar;
