import React, { useEffect, useState } from 'react'
import Layout from '../../common/Layout'
import ReactSelect from 'react-select'
import { dispatchMmvData } from '../../store/actions/userActions';
import { GetDataWithToken, PostDataWithToken } from '../../constants/ApiHelper';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FaDownload } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';

const TpError = () => {
    const mmvData = useSelector(state => state?.root?.mmvData)
    const userDetails = useSelector(state => state?.root?.userDetails)
    const apiUrl = {
        IF: 'https://api.insurefast.in/api/v1/',
        // IF: 'https://test.insurefast.in/api/v1/',
        EC: 'https://api.expertcover.in/api/v3/'
    }
    useEffect(() => {
        if (userDetails?.productOwner === 'EC') {
            dispatchMmvData('apiUrl', apiUrl?.EC);
        } else if (userDetails?.productOwner === 'IF') {
            dispatchMmvData('apiUrl', apiUrl?.IF);
        }
    }, [])

    const [loading, setLoading] = useState(false);
    const [leadId, setLeadId] = useState('')
    const [caseId, setCaseId] = useState('')
    const [insurer, setInsurer] = useState('')
    const [insurerData, setInsurerData] = useState([])
    const [apiName, setApiName] = useState([])
    const [selectedApiName, setSelectedApiName] = useState(null)
    const [tpErrorData, setTpErrorData] = useState([])
    const [filter, setFilter] = useState(false);
    const [totalPage, setTotalPage] = useState('')
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [itemOffset, setItemOffset] = useState(0);
    const [pageLimit, setPageLimit] = useState(10)
    const [currentPage, setCurrentPage] = useState(0)

    console.log("apiName", apiName);


    const handleLeadIdChange = (e) => {
        setCaseId("")
        setInsurer("")
        setTpErrorData(null)
        dispatchMmvData("apiName", "")
        setSelectedApiName("")
        dispatchMmvData("insurerId", "")

        setLeadId(e.target.value)

    }
    const handleCaseIdChange = (e) => {
        setCaseId(e.target.value)

    }
    const getAllIns = () => {
        GetDataWithToken(mmvData?.apiUrl, 'admin/getAllInsData').then(res => {
            setLoading(false);
            if (res?.status === 'success') {
                setInsurerData(res?.data);
            } else {
                console.log('Insurer Data', res?.status);
            }
        });
    }

    const getApiName = () => {
        setLoading(true);

        GetDataWithToken(mmvData?.apiUrl, "motor/apiNames")
            .then((res) => {
                const data = res?.data;

                if (data) {
                    const options = Object.entries(data).map(([key, value]) => ({
                        label: value,
                        value: value,
                    }));

                    setApiName(options);
                } else {
                    setApiName([]);
                }
            })
            .catch((error) => {
                console.error("Error fetching API names:", error);
                setApiName([]);
            })
            .finally(() => setLoading(false));
    }
    const handleSelectApiName = (val, selectedOption) => {
        setSelectedApiName(selectedOption)
        dispatchMmvData('apiName', val)

    }

    useEffect(() => {
        getAllIns()
        getApiName()
    }, [])
    const handleSelectInsurer = (val, insurerData) => {
        setInsurer(val)
        dispatchMmvData('insurerId', val)
        const selectedInsurer = insurerData.find(x => x.insurerId == val)
        const insurerName = selectedInsurer ? selectedInsurer.insurerName : ''
        const mappingName = selectedInsurer ? selectedInsurer.mappingName : ''
        dispatchMmvData('insurerName', insurerName)
        dispatchMmvData('mappingName', mappingName)
    }

    const handleSubmit = () => {
        const isAnyFieldFilled = leadId || caseId || insurer || selectedApiName;

        if (!isAnyFieldFilled) {
            toast.error("At least one field is required.");
            return;
        }
        setFilter(true)
        handleTpError();
        
    };

    const handleTpError = async () => {
        try {
            setLoading(true);

            const mapObj = {
                customerLeadId: leadId,
                caseId: caseId,
                insurerId: mmvData?.insurerId,
                apiName: mmvData?.apiName,
                limit: pageLimit,
                currentPage: currentPage,

            };

            const response = await PostDataWithToken(mmvData?.apiUrl, 'motor/thirdPartyLogs', mapObj);

            console.log("Response status:", response);
            setLoading(false);

            if (response && response?.status === "success") {
                setTpErrorData(response?.data?.logs);
                setTotalPage(response?.data?.pagination)
                

            } else {
                toast.error('No data returned from the server');
            }
        } catch (error) {
            setLoading(false);
            toast.error('Something went wrong');
            console.error("Error during API call:", error);
        }
    };

    const downloadTxtFile = (item) => {
        const formattedData = `
    API URL - ${item.apiUrl}
    --------------

    Headers - ${typeof item.headers == "string" ? item.headers : JSON.stringify(item.headers)}
    ---------------


    Request -   ${typeof item.requestData == "string" ? item.requestData : JSON.stringify(item.requestData)}
    ---------------


    Response -    ${typeof item.responseData == "string" ? item.responseData : JSON.stringify(item.responseData)}
    -------------------------
 
    ——————————
    `;
        const blob = new Blob([formattedData], { type: 'text/plain;charset=utf-8' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${mmvData?.mappingName}_thirdPartyLogs.txt`;
        link.click();
    };
    const handlePageClick = async (e) => {
        const newPage = e.selected;
        const newOffset = newPage * itemsPerPage;
    
        setCurrentPage(newPage);
        setItemOffset(newOffset);
    
        // Fetch data for the new page
        await handleTpError(newOffset, itemsPerPage);
    };
    
    const handleChangeItemPerPage = async (value) => {
        const newItemsPerPage = parseInt(value, 10);
        setItemsPerPage(newItemsPerPage);
    
        // Reset to the first page when changing the page size
        setCurrentPage(0);
        setItemOffset(0);
    
        // Fetch data for the new page size
        await handleTpError(0, newItemsPerPage);
    };
    
    useEffect(() => {
        if(itemOffset && itemsPerPage){
            handleTpError();
        }
       
    }, [itemOffset, itemsPerPage]);

    return (
        <div>
            <Layout>
                <div className='container-fluid py-4 dash_main'>
                    <div className='row'>
                        <div className='card mb-4'>
                            <div className='card-body pt-0 pb-2'>
                                <div className='row mt-2'>
                                    {/* <div className='col-xl-8 col-lg-6 col-md-6 col-12 d-flex align-items-center gao-1'> */}
                                    <div className='col-4'>
                                        <label>customerLeadId</label>
                                        <div className='mb-3'>
                                            <input
                                                type='search'
                                                placeholder='customerLeadId'
                                                className='form-control'
                                                value={leadId}
                                                onChange={handleLeadIdChange}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <label>Case Id</label>
                                        <div className='mb-3'>
                                            <input
                                                type='search'
                                                placeholder='Case Id'
                                                className='form-control'
                                                value={caseId}
                                                onChange={handleCaseIdChange}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-xl-3 col-lg-3 col-md-6 col-12'>
                                        <label>Insurer</label>
                                        <div className='mb-3'>
                                            <select
                                                className='form-control'
                                                onChange={e =>
                                                    handleSelectInsurer(e.target.value, insurerData)
                                                }
                                                value={insurer}
                                            >
                                                <option value=''>Select Insurance</option>
                                                {insurerData?.map((item, index) => (
                                                    <option key={index} value={item?.insurerId}>
                                                        {item?.mappingName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <label>Api Name</label>
                                        <div className='mb-3'>
                                            <ReactSelect
                                                options={apiName}
                                                placeholder="Select API"
                                                value={selectedApiName}
                                                onChange={e => {
                                                    handleSelectApiName(e?.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {/* </div> */}
                                    <div className='col-xl-7 col-lg-3 col-md-6 col-12'>
                                        <label></label>
                                        <div className='mb-3 d-flex justify-content-end gap-3'>
                                            <button
                                                type='button'
                                                className='btn btn-sec'
                                                onClick={handleSubmit}
                                            //   disabled={loading}
                                            >
                                                {loading ? 'Loading...' : 'Submit'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {filter ? (
                            <>
                                {loading ? (
                                    <>
                                        <div className='loading'>
                                            <div className='spinner'></div>
                                            <p>Loading...</p>
                                        </div>

                                    </>
                                ) : (
                                    <>
                                        <div className='card-body pt-0 pb-2'>
                                            <div className='table-responsive p-0'>
                                                <table className='table align-items-center mb-0 table-striped'>
                                                    <thead className='table-dark text-white'>
                                                        <tr>

                                                            {/* <th className='text-uppercase text-xxs font-weight-bolder text-dark'>
                                                             Case ID
                                                         </th>
                                                        */}
                                                            <th className='text-uppercase text-xs  fw-bold fs-3'>
                                                                Insures
                                                            </th>
                                                            {/* <th className='text-uppercase text-xs  '>
                                                                Api Name
                                                            </th> */}
                                                            <th className='text-uppercase text-xs  '>
                                                                Api URL
                                                            </th>
                                                            <th className='text-uppercase text-xs  '>
                                                                requestData
                                                            </th>
                                                            <th className='text-uppercase text-xs  '>
                                                                responseData
                                                            </th>
                                                            <th className='text-uppercase text-xs '>
                                                                Action
                                                            </th>
                                                            <th className='text-uppercase text-xs  fs-bold'>
                                                                Time
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {tpErrorData && tpErrorData.length > 0 ? (
                                                            tpErrorData.map((item, index) => (
                                                                <tr key={index}>
                                                                    {/* <td>{item.caseId}</td> */}
                                                                    <td>
                                                                        {item.insurerId}
                                                                    </td>
                                                                    {/* <td>
                                                                        {item.apiName}
                                                                    </td> */}
                                                                    <td>
                                                                        <textarea className='border-0 bg-transparent' cols="20" readOnly style={{ resize: 'none' }}>{item.apiUrl}</textarea>
                                                                    </td>

                                                                    <td>
                                                                        <textarea rows="4" cols="50" className='border-0' readOnly>{typeof item.requestData == "string" ? item.requestData : JSON.stringify(item.requestData)}</textarea>
                                                                    </td>
                                                                    <td>
                                                                        <textarea rows="4" cols="40" className='border-0' readOnly>{typeof item.responseData == "string" ? item.responseData : JSON.stringify(item.responseData)}</textarea>

                                                                    </td>
                                                                    <td onClick={() => downloadTxtFile(item)}>
                                                                        <FaDownload />
                                                                    </td>
                                                                    <td>{new Date(item.createdAt).toLocaleString([], { dateStyle: 'short', timeStyle: 'medium' })}</td>



                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="6" className="text-center">No data available</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>

                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                <div className="text-center">
                                    No data available
                                </div>
                            </>
                        )



                        }
                        <div className="d-flex align-items-center justify-content-between flex-wrap mt-3">
                            <div>
                                <p>
                                    Showing {itemOffset + 1} -{' '}
                                    {/* {Math.min(itemOffset + itemsPerPage, errorData.length)}  */}
                                    {totalPage?.pageSize}
                                    of{' '}
                                    {totalPage?.totalRecords}
                                </p>
                            </div>
                            <div className="main-page-item">
                                <div className="show-page">
                                    <select
                                        className="form-control"
                                        onChange={(e) => handleChangeItemPerPage(e.target.value)}
                                    >
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={30}>30</option>
                                        <option value={50}>50</option>
                                    </select>
                                </div>
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel=">"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={totalPage?.totalPages}
                                    previousLabel="<"
                                    renderOnZeroPageCount={null}
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </Layout>
        </div>
    )
}

export default TpError