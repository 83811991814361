import React, { useEffect, useState } from 'react';
import Layout from '../../common/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { PostDataWithToken } from '../../constants/ApiHelper';
import ReactPaginate from 'react-paginate';
import { dispatchMmvData } from '../../store/actions/userActions';

const QuoteError = () => {
    const dispatch = useDispatch();
    const mmvData = useSelector((state) => state?.root?.mmvData);

    const userDetails = useSelector(state => state?.root?.userDetails)
    const apiUrl = {
        IF: 'https://api.insurefast.in/api/v1/',
        // IF: 'https://test.insurefast.in/api/v1/',
        EC: 'https://api.expertcover.in/api/v3/'
    }
    useEffect(() => {
        if (userDetails?.productOwner === 'EC') {
            dispatchMmvData('apiUrl', apiUrl?.EC);
        } else if (userDetails?.productOwner === 'IF') {
            dispatchMmvData('apiUrl', apiUrl?.IF);
        }
    }, [])

    const [loading, setLoading] = useState(false);
    const [leadId, setLeadId] = useState('');
    const [filter, setFilter] = useState(false);
    const [errorData, setErrorData] = useState([]);
    const [caseId, setCaseId] = useState('')

    // Pagination States
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [itemOffset, setItemOffset] = useState(0);
    const [totalPage, setTotalPage] =useState('')
    const [pageLimit, setPageLimit] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [error, setError] = useState("");


    const handleLeadIdChange = (e) => {
        setLeadId(e.target.value);
        if (error) setError(""); 
    };

    const handleSubmit = () => {
        const hasError = handleErrorSet();
    
        if (!hasError) {
            setFilter(true); // Proceed only if no validation error
            handleError();
        }
       
    };
    const handleErrorSet = () => {
        if (!leadId && !caseId) {
            // setError("At least one field (Customer Lead ID or Case Id) is required.");
            toast.error("At least one field (Customer Lead ID or Case Id) is required.");
            return true; // Return true to indicate an error
        }
        setError(""); // Clear error if validation passes
        return false; // Return false to indicate no error
    };

    useEffect(()=> {
        if(pageLimit && currentPage){

            handleError()

        }
        
    },[pageLimit, currentPage])

    const handleError = async () => {
        try {
            setLoading(true);
            const mapObj = { 
                customerLeadId: leadId,
                caseId: caseId,
                limit: pageLimit,
                currentPage: currentPage,
             };
            const response = await PostDataWithToken(
                mmvData?.apiUrl,
                'motor/errorHandling',
                mapObj
            );
            setLoading(false);
            if (response && response?.status) {
                setErrorData(response?.data.data);
                setTotalPage(response?.data?.pagination)
                console.log("response?.data.data?.pagination",totalPage?.totalPages);
                
                
            } else {
                toast.error('No data returned from the server');
            }
        } catch (error) {
            setLoading(false);
            toast.error('Something went wrong');
            console.error('Error during API call:', error);
        }
    };

    // useEffect(() => {
    //     // Update current items and page count when data or pagination parameters change
    //     const endOffset = itemOffset + itemsPerPage;
    //     setCurrentItems(errorData.slice(itemOffset, endOffset));
    //     setPageCount(Math.ceil(errorData.length / itemsPerPage));
    // }, [itemOffset, itemsPerPage, errorData]);

    const handlePageClick = (e) => {
        const newOffset = e.selected * itemsPerPage;
        setItemOffset(newOffset);
    };

    const handleChangeItemPerPage = (value) => {
        // setItemsPerPage(parseInt(value, 10));
        setPageLimit(value)
        setItemOffset(0); // Reset to first page
    };
    const handleCaseIdChange = (e) => {
        setCaseId(e.target.value)
        if (error) setError("");
        dispatchMmvData("caseId",caseId)

    }
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleSubmit();
        }
    };
    return (
        <div>
            <Layout>
                <div className="container-fluid py-4 dash_main">
                    <div className="row">
                        <div className="card mb-4">
                            <div className="card-body pt-0 pb-2">
                                <div className="row mt-2">
                                    {/* <div className="col-xl-8 col-lg-6 col-md-6 col-12 d-flex align-items-center gao-1"> */}
                                        <div className="col-4">
                                            <label>Customer Lead ID</label>
                                            <div className="mb-3">
                                                <input
                                                    type="search"
                                                    placeholder="Customer Lead ID"
                                                    className="form-control"
                                                    value={leadId}
                                                    onChange={handleLeadIdChange}
                                                    onKeyDown={handleKeyDown}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-4'>
                                        <label>Case Id</label>
                                        <div className='mb-3'>
                                            <input
                                                type='search'
                                                placeholder='Case Id'
                                                className='form-control'
                                                value={caseId}
                                                onChange={handleCaseIdChange}
                                            />
                                        </div>
                                    </div>
                                    {/* </div> */}
                                    <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                                        <label></label>
                                        <div className="mb-3 d-flex justify-content-end gap-3">
                                            <button
                                                type="button"
                                                className="btn btn-sec"
                                                onClick={handleSubmit}
                                                disabled={loading}
                                            >
                                                {loading ? 'Loading...' : 'Submit'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {filter ? (
                            <>
                                {loading ? (
                                    <>
                                        <div className='loading'>
                                            <div className='spinner'></div>
                                            <p>Loading...</p>
                                        </div>

                                    </>
                                ) : (
                                    <>
                                        <div className="card-body pt-0 pb-2">
                                            <div className="table-responsive p-0">
                                                <table className="table align-items-center mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Case ID</th>
                                                            <th>Insurer</th>
                                                            <th>Error Code</th>
                                                            <th>Error Messages</th>
                                                            <th>Error Display Messages</th>
                                                            <th>Time</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {errorData && errorData.length > 0 ? (
                                                            errorData.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td>{item.caseId}</td>
                                                                    <td>{item.insurerName}</td>
                                                                    <td>{item.errorCode}</td>
                                                                    <td>{item.errorMessage}</td>
                                                                    <td>{item.errordisplayMessage}</td>
                                                                    <td>{new Date(item.createdAt).toLocaleString([], { dateStyle: 'short', timeStyle: 'medium' })}</td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="6" className="text-center">
                                                                    No data available
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                          
                                        </div>
                                    </>
                                )}

                            </>
                        ) :
                            (
                                <>
                                    <div className="text-center">
                                        No data available
                                    </div>
                                </>
                            )


                        }
                          <div className="d-flex align-items-center justify-content-between flex-wrap mt-3">
                                                <div>
                                                    <p>
                                                        Showing {itemOffset + 1} -{' '}
                                                        {/* {Math.min(itemOffset + itemsPerPage, errorData.length)}  */}
                                                        {totalPage?.limit}
                                                        of{' '}
                                                        {totalPage?.totalRecords}
                                                    </p>
                                                </div>
                                                <div className="main-page-item">
                                                    <div className="show-page">
                                                        <select
                                                            className="form-control"
                                                            onChange={(e) => handleChangeItemPerPage(e.target.value)}
                                                        >
                                                            <option value={10}>10</option>
                                                            <option value={20}>20</option>
                                                            <option value={30}>30</option>
                                                            <option value={50}>50</option>
                                                        </select>
                                                    </div>
                                                    <ReactPaginate
                                                        breakLabel="..."
                                                        nextLabel=">"
                                                        onPageChange={handlePageClick}
                                                        pageRangeDisplayed={5}
                                                        pageCount={totalPage?.totalPages}
                                                        previousLabel="<"
                                                        renderOnZeroPageCount={null}
                                                        containerClassName="pagination"
                                                        activeClassName="active"
                                                        pageClassName="page-item"
                                                        pageLinkClassName="page-link"
                                                        previousClassName="page-item"
                                                        previousLinkClassName="page-link"
                                                        nextClassName="page-item"
                                                        nextLinkClassName="page-link"
                                                        breakClassName="page-item"
                                                        breakLinkClassName="page-link"
                                                    />
                                                </div>
                                            </div>
                    </div>
                </div>
            </Layout>
        </div>
    );
};

export default QuoteError;
