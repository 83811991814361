
import React, { useEffect, useState } from 'react'
import Layout from '../../common/Layout'
import { dispatchMmvData } from '../../store/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from 'react-select'
import { PostDataWithToken } from '../../constants/ApiHelper';
import { FaPlus } from 'react-icons/fa';
import { Modal, ModalBody } from 'reactstrap';

const AddMmv = () => {
    const dispatch = useDispatch()
    const mmvData = useSelector(state => state?.root?.mmvData)
    const userDetails = useSelector(state => state?.root?.userDetails)
    const apiUrl = {
        IF: 'https://api.insurefast.in/api/v1/',
        // IF :'https://test.insurefast.in/api/v1/',
        EC: 'https://api.expertcover.in/api/v3/'
    }

    const [vehicleType, setVehicleType] = useState('')
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false)

    const [makeData, setMakeData] = useState([])
    const [modelData, setModelData] = useState([])
    const [varientData, setVarientData] = useState([])

    const [makeId, setMakeId] = useState('')
    const [modelId, setModelId] = useState('')

    const [selectedMake, setSelectedMake] = useState(null)
    const [selectedModal, setSelectedModal] = useState(null)
    const [selectedVarient, setSelectedVarient] = useState(null)

    const handleSelectVehicleType = val => {

        const vehicleTypeClean = val?.trim();  // This ensures no trailing commas or spaces
        setVehicleType(vehicleTypeClean);
        dispatchMmvData('vehicleType', vehicleTypeClean);

        if (userDetails?.productOwner === 'EC') {
            dispatchMmvData('apiUrl', apiUrl?.EC);
        } else if (userDetails?.productOwner === 'IF') {
            dispatchMmvData('apiUrl', apiUrl?.IF);
        }

    };
    useEffect(() => {
        if (vehicleType) {
            getMakeData()
        }
    }, [vehicleType]);

    const getMakeData = val => {
        setLoading(true)
        let makeObj = {
            vehicleType: mmvData?.vehicleType
        }
        PostDataWithToken(mmvData?.apiUrl, 'admin/getAllMakeData', makeObj).then(
            res => {
                let data = res?.data
                let i = 0
                let arr = []
                while (i < data?.length) {
                    let item = data[i]
                    arr.push({
                        label: item?.make,
                        value: item?.makeId
                    })
                    i++
                }
                setMakeData(arr)
                setLoading(false)
            }
        )
    }

    // Get Modal Data
    const getModelData = val => {
        setModelData([])
        setVarientData([])

        dispatchMmvData('makeId', val)
        setLoading(true)
        let makeObj = {
            vehicleType: mmvData?.vehicleType,
            makeId: val
        }
        PostDataWithToken(mmvData?.apiUrl, 'admin/getAllModelData', makeObj).then(
            res => {
                let data = res?.data
                let i = 0
                let arr = []
                while (i < data?.length) {
                    let item = data[i]
                    arr.push({
                        label: item?.model,
                        value: item?.modelId
                    })
                    i++
                }
                setModelData(arr)
                setLoading(false)
            }
        )
    }
    //Get Variant Data
    const getVariantData = val => {
        setLoading(true)
        dispatchMmvData('modelId', val)
        let makeObj = {
            vehicleType: mmvData?.vehicleType,
            makeId: makeId ? makeId : mmvData?.makeId,
            modelId: val
        }
        PostDataWithToken(mmvData?.apiUrl, 'admin/getAllVariantData', makeObj).then(
            res => {
                let data = res?.data
                let i = 0
                let arr = []
                while (i < data?.length) {
                    let item = data[i]
                    arr.push({
                        label: item?.variant,
                        value: item?.variantId
                    })
                    i++
                }
                setVarientData(arr)
                setLoading(false)
            }
        )
    }

    const handleSelectMake = (val, selectedOption) => {
        setMakeId(val)
        setSelectedMake(selectedOption)
        dispatchMmvData('makeId', val)
    }

    const handleSelectModel = (val, selectedOption) => {
        setModelId(val)
        setSelectedVarient(null)
        setSelectedModal(selectedOption)
        dispatchMmvData('modelId', val)
    }

    const handleSelectVarient = (val, selectedOption) => {
        setSelectedVarient(selectedOption)
        dispatchMmvData('varientId', val)
    }

    const [makeModal, setMakeModal] = useState(false)
    const [modelModal, setModelModal] = useState(false)
    const [varientModal, setVarientModal] = useState(false)
    const closeModal = () => {
        setMakeModal(false)
    }
    const closeModelModal = () => {
        setModelModal(false)
    }
    const closeVarientModal = () => {
        setVarientModal(false)
    }

    return (
        <div>
            <Layout>
                <div className='container-fluid py-4 dash_main'>
                    <div className='row'>
                        <div className='card mb-4'>
                            <div className='card-body pt-0 pb-2'>
                                <div className='row mt-2'>
                                    <div className='col-xl-4 col-lg-3 col-md-6 col-12'>
                                        <label>Vehicle Type</label>
                                        <div className='mb-3'>
                                            <select
                                                className='form-control'
                                                onChange={e => handleSelectVehicleType(e.target.value)}
                                                value={vehicleType}
                                                name='vehicleType'
                                                id='vehicleType'
                                            >
                                                <option value=''>Select Vehicle</option>
                                                <option value='2w'>Two Wheeler</option>
                                                <option value='4w'>Private Car</option>
                                                <option value='pcv'>Passenger Carrying Vehicle</option>
                                                <option value='gcv'>Goods Carrying Vehicle</option>
                                                <option value='misc'>Miscellaneous Vehicle</option>
                                            </select>
                                            {errors.vehicleType && (
                                                <div className='text-danger'>{errors.vehicleType}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-xl-4 col-lg-4 col-md-6 col-12 d-flex'>
                                        <div className='col-10'>
                                            <label>Make</label>
                                            <ReactSelect
                                                options={makeData}
                                                name='Make'
                                                placeholder='Select make'
                                                value={selectedMake}
                                                onChange={e => {
                                                    handleSelectMake(e?.value)
                                                    getModelData(e?.value)
                                                }}
                                            />
                                        </div>

                                        <div>
                                        <div
                                            className='add-sec'
                                        onClick={() => setMakeModal(true)}
                                        >
                                            <FaPlus />
                                        </div>
                                        <div className='hide'>Add New</div>
                                        </div>
                                    </div>
                                    <div className='col-xl-4 col-lg-4 col-md-6 col-12 d-flex'>
                                        <div className='col-10'>
                                            <label>Model</label>
                                            <ReactSelect
                                                options={modelData}
                                                name='Model'
                                                placeholder='Select model'
                                                value={selectedModal}
                                                onChange={e => {
                                                    handleSelectModel(e?.value)
                                                    getVariantData(e?.value)
                                                }}
                                            />
                                        </div>

                                        <div>
                                        <div
                                            className='add-sec'
                                        onClick={() => setModelModal(true)}
                                        >
                                            <FaPlus />
                                        </div>
                                        <div className='hide'>Add New</div>
                                        </div>
                                     
                                    </div>
                                    <div className='col-xl-4 col-lg-4 col-md-6 col-12 d-flex'>
                                        <div className='col-10'>
                                            <label>Varient</label>
                                            <ReactSelect
                                                options={varientData}
                                                name='Varient'
                                                placeholder='Select Varient'
                                                value={selectedVarient}
                                                onChange={e => handleSelectVarient(e?.value)}
                                            />
                                        </div>

                                        <div>
                                        <div
                                            className='add-sec'
                                        onClick={() => setVarientModal(true)}
                                        >
                                            <FaPlus />
                                        </div>
                                        <div className='hide'>Add New</div>
                                        </div>
                                    </div>
                                    <div className='col-xl-8 col-lg-3 col-md-6 col-12'>
                                        <label></label>
                                        <div className='mb-3 d-flex justify-content-end gap-3'>
                                            <button
                                                type='button'
                                                className='btn btn-sec'
                                            //   onClick={handleSubmit}
                                            //   disabled={loading}
                                            >
                                                {loading ? 'Loading...' : 'Submit'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <Modal
                    isOpen={makeModal}
                    toggle={closeModal}
                    className='logout_modal'
                    backdrop = "static"
                >
                    <div className='close-button' onClick={() => closeModal()}>
                        X
                    </div>
                    <ModalBody>
                        <div className=''>
                            <div className='row'>
                                <div className='col-xl-12 col-lg-3 col-md-6 col-12'>
                                    <div className='mb-3'>
                                        <label>Make Name</label>
                                        <input
                                            type='text'
                                            placeholder='Enter Make Name'
                                            className='form-control'
                                            name='Make'
                                            required
                                        //   {...register2('contentType')}
                                        />
                                    </div>
                                </div>
                                <div className='col-xl-12 col-lg-3 col-md-6 col-12'>
                                        <label></label>
                                        <div className='mb-3 d-flex justify-content-center gap-3'>
                                            <button
                                                type='button'
                                                className='btn btn-sec'
                                            //   onClick={handleSubmit}
                                            //   disabled={loading}
                                            >
                                                {loading ? 'Loading...' : 'Submit'}
                                            </button>
                                        </div>
                                    </div>
                            </div>

                        </div>
                    </ModalBody>
                </Modal>
                <Modal
                    isOpen={modelModal}
                    toggle={closeModelModal}
                    className='logout_modal'
                    backdrop = "static"
                >
                    <div className='close-button' onClick={() => closeModelModal()}>
                        X
                    </div>
                    <ModalBody>
                        <div className=''>
                            <div className='row'>
                                <div className='col-xl-12 col-lg-3 col-md-6 col-12'>
                                    <div className='mb-3'>
                                        <label>Model Name</label>
                                        <input
                                            type='text'
                                            placeholder='Enter Make Name'
                                            className='form-control'
                                            name='Model'
                                            required
                                        //   {...register2('contentType')}
                                        />
                                    </div>
                                </div>
                                <div className='col-xl-12 col-lg-3 col-md-6 col-12'>
                                        <label></label>
                                        <div className='mb-3 d-flex justify-content-center gap-3'>
                                            <button
                                                type='button'
                                                className='btn btn-sec'
                                            //   onClick={handleSubmit}
                                            //   disabled={loading}
                                            >
                                                {loading ? 'Loading...' : 'Submit'}
                                            </button>
                                        </div>
                                    </div>
                            </div>

                        </div>
                    </ModalBody>
                </Modal>
                <Modal
                    isOpen={varientModal}
                    toggle={closeVarientModal}
                    className='logout_modal'
                    backdrop = "static"
                >
                    <div className='close-button' onClick={() => closeVarientModal()}>
                        X
                    </div>
                    <ModalBody>
                        <div className=''>
                            <div className='row'>
                                <div className='col-xl-12 col-lg-3 col-md-6 col-12'>
                                    <div className='mb-3'>
                                        <label>Varient Name</label>
                                        <input
                                            type='text'
                                            placeholder='Enter Make Name'
                                            className='form-control'
                                            name='Make'
                                            required
                                        //   {...register2('contentType')}
                                        />
                                    </div>
                                </div>
                                <div className='col-xl-12 col-lg-3 col-md-6 col-12'>
                                        <label></label>
                                        <div className='mb-3 d-flex justify-content-center gap-3'>
                                            <button
                                                type='button'
                                                className='btn btn-sec'
                                            //   onClick={handleSubmit}
                                            //   disabled={loading}
                                            >
                                                {loading ? 'Loading...' : 'Submit'}
                                            </button>
                                        </div>
                                    </div>
                            </div>

                        </div>
                    </ModalBody>
                </Modal>
            </Layout>
        </div>
    )
}

export default AddMmv